/* globals ROUTES */
/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */
/* global _paq */

import React, { useState, useEffect, useContext } from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import "typeface-work-sans"
import Header from "./header"
import "../styles/main.scss"
import NavBar from "./navBar"
import {
  TOKEN_EXPIRED,
  PLAYER_EVENT_PLAY,
  PLAYER_EVENT_PAUSE,
  PRESENTATION_STATUS_LIVE,
} from "../utils/constants"
import { isLoggedIn, clearClientData } from "../services/auth"
import { getNestedObjectValue } from "../utils/global"
import Notification from "./shared/notification"
import { miniPlayerContext } from "../context/miniPlayer"
import { livePlayerContext } from "../context/livePlayer"
import { onDemandPlayerContext } from "../context/onDemandPlayer"

import { playerEvent } from "../utils/analytics"
import { SocketContext } from "../context/socket";
import { handleRemoveLiveViewer } from '../utils/socket/socketOperations';
import { isMatomoEnabled } from "../data/global"
import LivePlayer from "./layout/LivePlayer"

const Layout = ({ children }) => {
  const [miniPlayerState, setminiPlayerState, setInitialMiniPlayerState] = useContext(miniPlayerContext);
  const [livePlayerState, setlivePlayerState, setInitialLivePlayerState] = useContext(livePlayerContext);
  const [onDemandPlayerState, setOnDemandPlayerState] = useContext(onDemandPlayerContext);
  const [eventTitle, setEventTitle] = useState("")
  const [notification, setNotification] = useState("");
  const [notificationTime, setNotificationTime] = useState("");

  const { socket, isConnected } = useContext(SocketContext);

  const fetchEventDetails = async () => {
    let response = await fetch(
      `${process.env.API_URL}/${process.env.EVENT_ID}/getEventDetails`
    )
    let data = await response.json()
    let event = getNestedObjectValue(data, "result.event")
    let eventTitle = event && event.title ? event.title : ""
    if (event && event.rocketChat) {
      window.localStorage.hasNetworking = Boolean(event.rocketChat.hasNetworking);
      window.localStorage.isNetworkingEnabled = Boolean(event.rocketChat.isNetworkingEnabled);
    } else {
      window.localStorage.hasNetworking = false;
      window.localStorage.isNetworkingEnabled = false;
    }
    if(event && event?.broadcastmessage?.msg && event?.broadcastmessage?.sentAt){
      setNotification(event.broadcastmessage.msg);
      setNotificationTime(event.broadcastmessage.sentAt)
    }
    setEventTitle(eventTitle)
  }

  const getLiveStream = async (isPlayerRequiredToAppend) => {
    setlivePlayerState((prev)=>({...prev, isLoading: true}))
    let response = await fetch(
      `${process.env.API_URL}/${process.env.EVENT_ID}/live`
    )
    response = await response.json()
    if (response?.status === 200) {
      setlivePlayerState((prev)=>({...prev, presentationId: response?.result?._id, status: PRESENTATION_STATUS_LIVE, data: response?.result,isLoading: false}))
      if(window.location.pathname === "/live" && isPlayerRequiredToAppend){
        const observer1 = new MutationObserver(() => {
          const player = document.getElementById("live-stream-player");
          let layoutPlayerEle = document.getElementById("live-page-player");
          if (layoutPlayerEle && player) {
            layoutPlayerEle.appendChild(player);
            observer1.disconnect();
          }
        });
        observer1.observe(document.body, { childList: true, subtree: true });
      }
      if (response.result?.slides?.length) {
        setminiPlayerState((prev)=>({...prev,activeSlideFromMP: response.result.activeSlide || response.result.slides[0]["_id"]}));
      }
    }else {
      const isOpenPath = Object.values(ROUTES).filter(menulink => menulink.isOpenPath).map(v=>v.path).includes(window.location.pathname)
      if(response.error === TOKEN_EXPIRED && !isOpenPath){
        clearClientData()
      }
      setInitialLivePlayerState()
      setInitialMiniPlayerState()
    }
  }

  useEffect(() => {
    fetchEventDetails();
    getLiveStream(false);
  }, [])


  if (isLoggedIn() && typeof window !== 'undefined' && window.location.pathname) {
    const path = window.location.pathname;
    
    if (!path.includes("live") && livePlayerState?.eventType === PLAYER_EVENT_PLAY) {
      pausePlayer(livePlayerState);
    }
  
    if (!path.includes("resources") &&  onDemandPlayerState?.eventType === PLAYER_EVENT_PLAY) {
      pausePlayer(onDemandPlayerState);
    }
  }

  useEffect(() => {
    if (!socket) return;
    // Subscribe to a channel
    const channel = socket.subscribe(process.env.EVENT_ID);
    channel.watch((data) => {
      if (data?.event === "statusChange") {
        const presentationData = data?.message;
        if (presentationData?.status === PRESENTATION_STATUS_LIVE && presentationData?.presentationId) {
          getLiveStream(true);
        } else if(presentationData?.status !== PRESENTATION_STATUS_LIVE){
          setInitialLivePlayerState()
          setInitialMiniPlayerState()
        }
      } else if (data?.event === "updatedBroadcastMessage") {
          setNotification(data?.message?.msg || "");
          setNotificationTime(data?.message?.sentAt || "");
      }
    });
    return () => {
      if (channel) {
        channel.unsubscribe();
      }
    };
  }, [socket]);

  function pausePlayer(playerState) {
    playerEvent({
      eventType: PLAYER_EVENT_PAUSE,
      presentationId: playerState.presentationId,
      status: playerState.status,
    });
    if (isConnected) {
      handleRemoveLiveViewer(socket, playerState.presentationId)
    }
  }

  return (
    <>    
      <Header siteTitle={eventTitle} />
      <div className="main-wrapper d-sm-flex">
        {isLoggedIn() && <NavBar liveStream={!!livePlayerState.presentationId} />} 
        <div className="content-wrapper d-sm-flex xs-between xs-column background-color-secondary-1">

          {isLoggedIn() && <Notification message={notification} time={notificationTime} />}

          {livePlayerState?.presentationId && !livePlayerState?.isLoading && livePlayerState?.data &&
            <div style={{ display: 'none', width: '300px', height: '200px' }} id="layout-player">
              <LivePlayer />
            </div>
          }

          <main className="main pb-0 d-xs-flex xs-row height-full">
            {children}
          </main>

          <footer className="footer-block background-color-secondary-2">
            <div className="container-fluid">
              <ul className="footer-content d-sm-flex xs-end xs-middle width-full pl-0 mb-0 pt-15 pb-15">
                <li className="mr-30 mb-xs-20">
                  <Link
                    className="font-secondary-regular color-monochrome-1"
                    to="/accessibility"
                  >
                    Accessibility
                  </Link>
                </li>
                <li className="mr-30 mb-xs-20">
                  <Link
                    className="font-secondary-regular color-monochrome-1"
                    to="/terms-of-use"
                  >
                    Terms of use
                  </Link>
                </li>
                <li>
                  <Link
                    className="font-secondary-regular color-monochrome-1"
                    to="/cookie-policy"
                  >
                    Cookies
                  </Link>
                </li>
              </ul>
            </div>
          </footer>
        </div>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default React.memo(Layout)
